import React, { useState } from 'react';
import classNames from 'classnames';

const nsBase = 'component';
const ns = `${ nsBase }-filter`;

const FilterTeam = (props) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }-team`]: true
	});

	const {
		title,
		filters,
		items,
		handleFilteredItems
	} = props;

	const [filterSelected, setFilterSelected] = useState('');

	const handleResetState = () => {
		if (filterSelected) {
			handleFilteredItems([...items]);
			setFilterSelected('');
		}
	};

	const filterItems = (selectedSlug) => {
		const filteredItems = [];

		items.map((item) => {
			item.filter.teamSlug.map((teamSlug) => {
				if (teamSlug === selectedSlug) {
					filteredItems.push(item);
				}
				return null;
			});
			return null;
		});

		handleFilteredItems([...filteredItems]);
	};

	const renderFilters = () => {
		return filters.map((filter) => {
			const filterSelectedCategoryClass = filterSelected === filter.slug ? 'active' : '';
			return (
				<div key={filter.slug} className={`${ ns }__category`}>
					<h2 className={`${ ns }__category-title ${ filterSelectedCategoryClass }`}>
						<button
							onClick={() => {
								filterItems(filter.slug);
								setFilterSelected(filter.slug);
							}}
						>
							{filter.title}
						</button>
					</h2>
				</div>
			);
		});
	};

	const filterSelectedClass = filterSelected ? 'filter-selected' : '';

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				<div className={`${ ns }__content ${ filterSelectedClass }`}>
					<h2 className={`${ ns }__title`}>
						<button onClick={(e) => { handleResetState(e); }}>{title}</button>
					</h2>
					<div className={`${ ns }__categories`}>
						{renderFilters()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FilterTeam;
