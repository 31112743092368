import React, { useState } from 'react';
import classNames from 'classnames';

import FilterTeam from '../FilterTeam';
import ContentFeed from '../ContentFeed';
import Reveal from '../Reveal';
import Image from '../Image';
import RenderStaticHTML from '../RenderStaticHTML';

const nsBase = 'component';
const ns = `${ nsBase }-all-teams`;

const teamItemView = ({ item }) => {
	return (
		<Reveal>
			<div className={`${ ns }__list-item`}>
				<div className={`${ ns }__list-item-image`}>
					<Image {...item.image} />
				</div>
				<h3 className={`${ ns }__list-item-name`}>
					{item.name}
				</h3>
				<div className={`${ ns }__list-item-content`}>
					{item.secondColumnLines && item.secondColumnLines.map((line) => {
						return <RenderStaticHTML key={line} html={line} />;
					})}
				</div>
			</div>
		</Reveal>
	);
};

const AllTeams = ({ teamFilters, team }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	// array of items to render
	const [filteredItems, setFilteredItems] = useState([...team]);
	const [filterTransition, setFilterTransition] = useState(false);

	const handleFilteredItems = (items) => {
		if (items !== filteredItems) {
			setFilterTransition(true);
			setTimeout(() => {
				setFilteredItems(items);
				setFilterTransition(false);
			}, 750);
		}
	};

	const filterTransitionClass = filterTransition ? 'filter-transition' : '';

	return (
		<div className={rootClassnames}>
			<FilterTeam
				title={'Our Team'}
				items={[...team]}
				filters={[...teamFilters]}
				handleFilteredItems={handleFilteredItems}
			/>
			<div className={`${ ns }__items ${ filterTransitionClass }`}>
				<ContentFeed
					items={filteredItems}
					ItemView={teamItemView}
					showAllItems
				/>
			</div>
		</div>
	);
};

export default AllTeams;
