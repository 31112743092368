import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import ImageCollage from '../components/ImageCollage';
import AllTeams from '../components/AllTeams';

const nsBase = 'page';
const ns = `${ nsBase }-team`;

const TeamPage = ({ data }) => {
	const rootClassnames = classNames({
		[`${ nsBase } ${ ns }`]: true
	});

	const {
		teamJson: {
			hero,
			teamFilters,
			team
		}
	} = data;

	return (
		<div className={rootClassnames}>
			<SEO title={'Team'} />
			<Hero
				variant={'green'}
				heading={hero.heading}
			>
				<ImageCollage
					images={hero.imageCollage.images}
					text={hero.imageCollage.text}
				/>
			</Hero>
			<AllTeams
				teamFilters={teamFilters}
				team={team}
			/>
		</div>
	);
};

export default TeamPage;

export const query = graphql`
  query GET_PAGE_TEAM {
		teamJson {
			hero {
				heading
				imageCollage {
					text,
					images {
						alt_text,
						main_image
					}
				}
			},
			teamFilters {
				title,
				slug
			}
			team {
				name,
				secondColumnLines,
				image {
					alt_text,
					main_image
				},
				filter {
					teamSlug
				}
			}
		}
	}
`;
