import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
// import { TimelineLite } from 'gsap';

import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-image-collage`;

const ImageCollage = ({ images, text }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	const imagesContainer = useRef(null);

	/**
	 * Stagger fade images in
	 *
	 * @param {*} container
	 */
	// const showImages = (container) => {
	// 	const imgs = container.querySelectorAll('li');
	// 	const tl = new TimelineLite();
	// 	tl.staggerTo(imgs, 1.3, {
	// 		y: 0
	// 	},
	// 	0.25);
	// 	tl.staggerTo(imgs, 1, {
	// 		opacity: 1
	// 	},
	// 	0.15, 0.5);
	// };

	/**
	 * Translate ImageCollage to the left on scroll
	 *
	 * @param {*} container
	 */
	const horizontalParallax = (container) => {
		const imgsContainer = container;
		const throttle = (type, name, ctx) => {
			const obj = ctx || window;
			let running = false;

			const func = () => {
				if (running) { return; }
				running = true;
				requestAnimationFrame(() => {
					obj.dispatchEvent(new CustomEvent(name));
					running = false;
				});
			};

			obj.addEventListener(type, func);
		};

		throttle('scroll', 'optimizedScroll');

		window.addEventListener('optimizedScroll', () => {
			const offset = (window.pageYOffset / 40);
			imgsContainer.style.transform = `translateX(-${ offset }%)`;
		});
	};

	useEffect(() => {
		// showImages(imagesContainer.current);
		horizontalParallax(imagesContainer.current);
	});

	if (!images) return null;

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__images`}>
				<Reveal>
					<ul ref={imagesContainer} className={`${ ns }__items`}>
						{images.map((image) => {
							const {
								alt_text: altText,
								main_image: mainImage
							} = image;

							return (
								<li key={uniqueId('envoy')} className={`${ ns }__item`}>
									<img src={mainImage} alt={altText} />
								</li>
							);
						})}
					</ul>
				</Reveal>
			</div>
			<div className={`${ ns }__text`}>
				<p>{text}</p>
			</div>
		</div>
	);
};

export default ImageCollage;
