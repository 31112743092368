import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Button from '../Button';

const nsBase = 'component';
const ns = `${ nsBase }-content-feed`;

const ContentFeed = (props) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true
	});

	const {
		showAllItems,
		items,
		nextVisibleAmount,
		ItemView,
		loadButton: {
			label
		}
	} = props;

	let {
		initialVisibleAmount
	} = props;

	// if showAllItems display all items in items array
	if (showAllItems) {
		initialVisibleAmount = items.length;
	}

	const [visible, setVisible] = useState(initialVisibleAmount);
	const [visibleItems, setvisibleItems] = useState([...items]);

	useEffect(() => {
		setvisibleItems([...items]);

		if (items.length < initialVisibleAmount) {
			setVisible(items.length);
		} else {
			setVisible(initialVisibleAmount);
		}
	}, [items, initialVisibleAmount]);

	const handleLoadMore = () => {
		setVisible((prevState) => {
			return (prevState + nextVisibleAmount);
		});
	};

	const renderContentFeed = () => {
		return visibleItems.slice(0, visible).map((item) => {
			return <ItemView key={item.name} item={item} />;
		});
	};

	return (
		<div className={rootClassnames}>
			<div className={'container-fluid'}>
				{items.length ? (
					<div className={`${ ns }__list`}>
						{renderContentFeed()}
					</div>

				) : (
					<h3 className={`${ ns }__no-items`}>Sorry, we couldn't find any companies that match your criteria. Try broadening your search.</h3> // eslint-disable-line
				)}
				{(visible < visibleItems.length) && (
					<div className={`${ ns }__load-more`}>
						<Button
							variant={'white'}
							onClick={handleLoadMore}
						>
							{label}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default ContentFeed;

ContentFeed.defaultProps = {
	showAllItems: false,
	items: [],
	initialVisibleAmount: 8,
	nextVisibleAmount: 4,
	ItemView: () => {
		return <div>Item View Default</div>;
	},
	loadButton: {
		label: 'Load More'
	}
};
